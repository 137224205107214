'use client';

import React, { FC, MouseEvent, useMemo } from 'react';

import cn from 'classnames';
import Image from 'next/image';

import { CustomizeData } from '@/app/(app-layout)/aibot/[chatbotId]/(layout)/customize/types';
import { GetWorkspaceUsersReturnType } from '@/app/actions/getWorkspaceUsers';
import Avatar from '@/components/avatar.component';
import MessengerWrapper from '@/components/messenger/messenger-wrapper.component';
import { useMessengerContext } from '@/context/messengerContext';
import useWindowSize from '@/hooks/useWindowSize';

type PageContentProps = {
  workspaceUsers: GetWorkspaceUsersReturnType;
};

const PageContent: FC<PageContentProps> = ({ workspaceUsers }) => {
  const windowWidth = useWindowSize();
  const width = windowWidth ?? 1200;

  const detailRef = React.useRef<HTMLDivElement>(null);

  const { chatbot } = useMessengerContext();

  const customize = useMemo(
    () => chatbot?.customize as CustomizeData,
    [chatbot],
  );

  const messengerHeaderColor = customize?.theme?.color?.header || '#1C86FA';
  const fullPageHeaderColor =
    customize?.fullPage?.theme?.headerColor || messengerHeaderColor;
  const fullPageTextColor = customize?.fullPage?.theme?.textColor || '#ffffff';
  const logo = customize?.theme?.headerLogo;

  const overlayBG = useMemo(() => {
    if (customize?.fullPage?.theme?.bgImage)
      return `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), url(${customize?.fullPage?.theme?.bgImage}), lightgray 50%`;
    if (customize?.fullPage?.theme?.bgColor)
      return customize?.fullPage?.theme?.bgColor;

    return 'transparent';
  }, [customize]);

  const toggleMessengerSize = (status: boolean) => {
    if (width > 1280) return;

    if (status) {
      detailRef.current?.classList.add('detail-section-hidden');
    }

    if (!status) {
      detailRef.current?.classList.remove('detail-section-hidden');
    }
  };

  const handleClickMessenger = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    toggleMessengerSize(true);
  };

  const handleClickOutside = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    toggleMessengerSize(false);
  };

  return (
    <div
      className="flex h-screen w-full !bg-cover !bg-center px-5 py-5 sm:px-8 sm:py-8 md:px-[80px] md:py-[60px] xl:px-[120px] xl:py-[90px] 2xl:px-[140px]"
      style={{
        background: overlayBG,
      }}
      onClick={handleClickOutside}
    >
      <div
        className={cn(
          'relative mx-auto flex flex-1 flex-col items-center justify-center xl:flex-row',
        )}
      >
        <div
          ref={detailRef}
          className={cn(
            'relative mb-8 mt-6 flex h-full w-full max-w-[450px] flex-1 flex-col items-center justify-center overflow-y-auto px-6 text-center text-white transition-all duration-300 ease-in-out xl:mb-0 xl:mr-[60px] xl:mt-0 xl:px-0',
          )}
          style={{
            background: 'transparent',
            color: fullPageTextColor,
          }}
        >
          {logo && (
            <Image
              className="mb-4 object-contain object-center"
              width={0}
              height={0}
              sizes="100vw"
              src={logo}
              alt="Logo"
              style={{
                maxHeight: '36px',
                minHeight: '32px',
                width: 'auto',
                height: '100%',
              }}
            />
          )}

          {customize?.fullPage?.header && (
            <h2 className="mb-4 text-xl font-semibold sm:text-2xl md:mb-[30px] md:text-3xl lg:text-4xl">
              {customize?.fullPage?.header}
            </h2>
          )}

          {customize?.fullPage?.showHumanAvatar && (
            <div className="mb-3 flex scale-75 -space-x-2 md:mb-4 md:scale-100">
              {workspaceUsers && !!workspaceUsers.length
                ? workspaceUsers.map((user) => (
                    <Avatar
                      key={user.id}
                      size={48}
                      src={user.avatar}
                      email={user.email}
                    />
                  ))
                : null}
            </div>
          )}
          {customize?.fullPage?.shortMessage && (
            <p className="text-sm font-normal sm:text-base lg:text-lg lg:leading-7">
              {customize?.fullPage?.shortMessage}
            </p>
          )}
        </div>
        <div
          className={cn(
            'drop-shadow-messenger flex h-full w-full max-w-[568px] overflow-hidden rounded-[25px]',
          )}
          onClick={handleClickMessenger}
        >
          <MessengerWrapper isFullPageView={true} />
        </div>
      </div>
    </div>
  );
};

export default PageContent;
