import { useCallback, useEffect, useState } from 'react';

import throttle from 'lodash/throttle';

export default function useWindowSize(): number | undefined {
  // Default to undefined for SSR compatibility
  const [width, setWidth] = useState<number | undefined>(undefined);

  const onResize = () => {
    // Only access window when in browser environment
    if (typeof window !== 'undefined') {
      const width = window.innerWidth;
      setWidth(width);
    }
  };

  const throttledOnResize = useCallback(throttle(onResize, 300), []);

  useEffect(() => {
    // Only run in browser environment
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', throttledOnResize);
      // Set initial width
      throttledOnResize();

      return () => {
        window.removeEventListener('resize', throttledOnResize);
        throttledOnResize.cancel();
      };
    }
  }, [throttledOnResize]);

  return width;
}
