'use client';

import React, { FC } from 'react';

import LockClosedIcon from '@heroicons/react/20/solid/LockClosedIcon';
import Link from 'next/link';

import { sendClientSidePosthogEvent } from '@/utils/posthog';

type UpgradeContentProps = {
  chatbotId: string;
};

const UpgradeContent: FC<UpgradeContentProps> = ({ chatbotId }) => (
  <div className="flex min-h-screen flex-col items-center justify-center px-5 py-4 text-center">
    <LockClosedIcon className="mb-5 h-10 w-10" aria-hidden="true" />
    <p className="mb-5 max-w-md text-xl">
      Full Page is available only for paid plans.
    </p>
    <Link
      className="rounded-full bg-black px-5 py-[7px] text-sm font-medium text-white"
      href="/dashboard/profile/billing"
      onClick={() => {
        sendClientSidePosthogEvent('app_billing_click', {
          chatbot_id: chatbotId,
          from: 'full_page_upgrade_banner',
        });
      }}
    >
      Upgrade
    </Link>
  </div>
);

export default UpgradeContent;
